














































































































































import { defineComponent, computed, ref, watch, reactive } from '@vue/composition-api'

import { useQuery, useShowError, useExpansion } from 'hooks'
import { PackingResult, HarvestResult, MultiLevelProduct } from 'typings'
import {
  endpoints,
  convertToWidthCharacter,
  mappingStock,
  groupMultiLevel,
  PACKING_RESULT_DES
} from 'utils'

import { LoaderComponent, StepButton, MultiLevelList, MultiLevelItem } from 'components'

interface Master {
  id: number
  name: string
}

interface SelectedLevel {
  rootIndex: number | null
  firstIndex: number | null
  secondIndex: number | null
}

interface ActionOptions {
  defaultItem: number | null
  defaultVariety: number | null
  defaultSize: number | null
  defaultQuality: number | null
}

const SelectResultDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    auctionDate: {
      type: String,
      required: true
    },
    assignment: {
      type: Object,
      required: true
    }
  },
  components: {
    LoaderComponent,
    StepButton,
    MultiLevelList,
    MultiLevelItem
  },
  setup(props, { root, emit }) {
    const { $toast, $store } = root
    const searchInfo = ref('')
    const date = ref(props.auctionDate)
    const dateDialog = ref(false)
    const isShowActionOptions = ref(false)
    const { getExpandPanelState, getCollapsePanelState } = useExpansion()
    const selectedLevel = reactive<SelectedLevel>({
      rootIndex: null,
      firstIndex: null,
      secondIndex: null
    })
    const actionOptions = ref<ActionOptions>({
      defaultItem: null,
      defaultVariety: null,
      defaultSize: null,
      defaultQuality: null
    })
    const url = computed(() => {
      if (!props.value) return null

      return `${endpoints.PACKING_RESULTS}get_packing_result_by_date?date=${date.value}`
    })
    const endPoint = ref(url.value)

    const { data: rawData, loading, error, isValidating } = useQuery(() => endPoint.value)
    useShowError($toast, error)

    const results = computed(() => {
      if (!rawData) return []

      let tmpData = rawData.value

      tmpData = tmpData.map((result: PackingResult | HarvestResult) => {
        return {
          ...result,
          packingStems: result.totalStems,
          assignStems: result.assignedStems
        }
      })

      const filterPackingResults = tmpData.filter((result: PackingResult | HarvestResult) => {
        const text = convertToWidthCharacter(searchInfo.value.toLowerCase().trim(), 'full')
        const { item, variety, size, quality } = result

        const searchStr = `${item.name}${variety.name}${size.name}${quality.name}`.toLowerCase()
        return searchStr.includes(text)
      })

      const groupPackingResults = groupMultiLevel(
        filterPackingResults,
        ['packingStems', 'orderStems', 'assignStems'],
        ['itemName', 'varietyName', 'qualitySizeName']
      )

      return mappingStock(
        groupPackingResults,
        {},
        false,
        PACKING_RESULT_DES
      ) as Array<MultiLevelProduct>
    })

    const packingListState = computed(() => $store.state.assign.packingListState)

    const updatePanelState = (mutation: string, panelState: Record<string, unknown>) => {
      $store.commit(mutation, panelState)
    }
    const currentDataState = computed(() => {
      let data = []
      let action = ''
      let keys: Array<string> = []
      let state = {}

      data = results.value
      action = 'setPackingListState'
      keys = ['itemName', 'varietyName']
      state = packingListState.value
      return { data, action, keys, state }
    })

    const expandAll = () => {
      const { data, action, keys, state } = currentDataState.value
      const argument = {
        data,
        keys,
        state,
        selected: selectedLevel
      }

      updatePanelState(action, getExpandPanelState(argument))
    }

    const collapseAll = () => {
      const { data, action, keys, state } = currentDataState.value
      const argument = {
        data,
        keys,
        state,
        selected: selectedLevel
      }

      updatePanelState(action, getCollapsePanelState(argument))
    }

    const getChooseDetail = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      const defaultItem = ref<Master | null>(null)
      const defaultVariety = ref<Master | null>(null)
      const defaultSize = ref<Master | null>(null)
      const defaultQuality = ref<Master | null>(null)

      defaultItem.value = results.value[rootIndex].children[0].children[0].children[0].item
      if (firstIndex !== null) {
        defaultVariety.value =
          results.value[rootIndex].children[firstIndex].children[0].children[0].variety
      }
      if (secondIndex !== null) {
        defaultSize.value =
          results.value[rootIndex].children[Number(firstIndex)].children[
            secondIndex
          ].children[0].size

        defaultQuality.value =
          results.value[rootIndex].children[Number(firstIndex)].children[
            secondIndex
          ].children[0].quality
      }

      return { defaultItem, defaultVariety, defaultSize, defaultQuality }
    }

    const openActionSheet = (
      rootIndex: number,
      firstIndex: number | null = null,
      secondIndex: number | null = null
    ) => {
      isShowActionOptions.value = true
      selectedLevel.rootIndex = rootIndex
      selectedLevel.firstIndex = firstIndex
      selectedLevel.secondIndex = secondIndex

      const { defaultItem, defaultVariety, defaultSize, defaultQuality } = getChooseDetail(
        rootIndex,
        firstIndex,
        secondIndex
      )
      if (defaultItem.value) {
        actionOptions.value.defaultItem = defaultItem.value.id as number
      }

      if (defaultVariety.value) {
        actionOptions.value.defaultVariety = defaultVariety.value.id as number
      }

      if (defaultSize.value) {
        actionOptions.value.defaultSize = defaultSize.value.id as number
      }

      if (defaultQuality.value) {
        actionOptions.value.defaultQuality = defaultQuality.value.id as number
      }
    }

    const title = computed(() => {
      return `${root.$t('assign.assignment_dialog.packing_result')} ${date.value}`
    })
    const search = () => {
      searchInfo.value = ''
      endPoint.value = url.value
    }

    const handleAdd = (id: number) => {
      const selectedResult = rawData.value.find(
        (result: PackingResult | HarvestResult) => result.id === id
      )
      emit('selected', selectedResult)
      emit('input', false)
      updatePanelState('setPackingListState', {})
    }

    const productName = computed(() => {
      let name = ''
      if (props.assignment.item) {
        name = `${name} ${props.assignment.item.name}`
      }
      if (props.assignment.variety) {
        name = `${name} ${props.assignment.variety.name}`
      }
      if (props.assignment.size) {
        name = `${name} ${props.assignment.size.name}`
      }
      if (props.assignment.quality) {
        name = `${name} ${props.assignment.quality.name}`
      }
      return name
    })

    const customerName = computed(() => {
      let name = ''
      if (props.assignment.customer) {
        name = `${name} [${props.assignment.customer?.code}] ${props.assignment.customer?.name}`
      }
      if (props.assignment.buyerInfo) {
        name = `${name} - ${props.assignment.buyerInfo}`
      }
      return name
    })

    watch(
      () => props.value,
      (value) => {
        if (value) {
          search()
        }
      }
    )

    const closeDialog = () => {
      updatePanelState('setPackingListState', {})
      emit('input', false)
    }

    return {
      results,
      loading,
      isValidating,
      searchInfo,
      title,
      handleAdd,
      search,
      date,
      dateDialog,
      isShowActionOptions,
      collapseAll,
      expandAll,
      openActionSheet,
      packingListState,
      updatePanelState,
      closeDialog,
      productName,
      customerName
    }
  }
})

export default SelectResultDialog

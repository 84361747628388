





























































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment'
import { snakeCase } from 'lodash'

import { useMutation } from 'hooks'
import {
  defaultObject,
  endpoints,
  showError,
  scrollBottom,
  scrollTop,
  toCamelCase,
  getSortStates,
  urlPath,
  matchData,
  toSnakeCase,
  frameBusEvent,
  backPage,
  checkIsOnPC,
  mappingKeyAndField
} from 'utils'
import {
  BoxType,
  Color,
  CommonProperty,
  Customer,
  IAssign,
  IBreakdown,
  IState,
  Item,
  OrderType,
  PackingResult,
  Quality,
  QualityGroup,
  Size,
  Unit,
  Variety,
  SizeGroup,
  SizesBySizeGroup,
  QualitiesByQualityGroup,
  VarietiesByItem,
  NurseryCompany,
  CustomerGroup,
  MappingKeyAndField
} from 'typings'

import {
  AddButton,
  BreakdownContainer,
  ChooseColor,
  ChooseNumber,
  ChooseProperty,
  ConfirmDelete,
  CreateCommonForm,
  SelectInputRow,
  SingleDetailItem
} from 'components'
import { api, framebus } from 'plugins'
import SelectResultDialog from './SelectResultDialog.vue'
import MarkOrderDialog from './MarkOrderDialog.vue'

interface Property {
  id: number
  name: string
}

const AssignForm = defineComponent({
  components: {
    CreateCommonForm,
    SelectInputRow,
    ChooseNumber,
    ChooseProperty,
    ChooseColor,
    ConfirmDelete,
    AddButton,
    SelectResultDialog,
    MarkOrderDialog,
    BreakdownContainer,
    SingleDetailItem
  },
  setup(props, { root, refs }) {
    const isShowAction = ref(false)
    const thisPage = ref('assign')
    const { $router, $route, $toast, $store } = root
    const { assignId } = $route.params
    let { isDuplicated } = $route.params
    const {
      order_detail_id: orderDetailId,
      auction_date: auctionDate,
      order_detail_info: orderDetailInfo
    } = $route.query
    const id = ref(Number(assignId) || null)
    const updateLoading = ref(false)
    const deleteLoading = ref(false)
    const onProcessing = ref(false)
    const isOnPC = checkIsOnPC()
    const LIST_PROPERTY = [
      'auctionDate',
      'orderType',
      'customer',
      'buyerInfo',
      'bundleNo',
      'item',
      'variety',
      'colors',
      'quality',
      'size',
      'quantity',
      'unit',
      'boxes',
      'boxType',
      'stems',
      'price',
      'amount'
    ]

    const batchAssignState = computed(() => $store.state.assign.batchAssignState)
    const selectedFromResult = computed(() => $store.state.assign.selectedFromResult)
    const listStateSetting = ref<Array<string>>([])
    const listStateStore = ref<IState>($store.state.listStates)
    const listStates = ref<Array<string>>([])
    const groupDataMaster = ref<Array<any>>([])

    const initResult: IAssign = defaultObject.initAssign
    const initBreakdown: IBreakdown = { ...defaultObject.initBreakdown }

    const assignment: IAssign = reactive({
      ...initResult,
      auctionDate: auctionDate ? String(auctionDate) : moment().format('YYYY-MM-DD')
    })

    assignment.orderType = { id: 1, code: 1, name: 'セリ' }

    const uploadLoading = ref(false)
    const currentProperty = ref('')
    const dialog = ref(false)
    const numberDialog = ref(false)
    const colorDialog = ref(false)
    const dateDialog = ref(false)
    const showDelete = ref(false)
    const markOrder = ref(false)
    const orderDetailIdAssigned = ref(orderDetailId ? [orderDetailId] : [])

    const items = ref<Array<Item>>([])
    const varieties = ref<Array<Variety>>([])
    const sizes = ref<Array<Size>>([])
    const colors = ref<Array<Color>>([])
    const boxTypes = ref<Array<BoxType>>([])
    const units = ref<Array<Unit>>([])
    const qualities = ref<Array<Quality>>([])
    const customers = ref<Array<Customer>>([])
    const orderTypes = ref<Array<OrderType>>([])
    const assignData = ref<any>([])
    const orderDetail = ref<any>([])
    const masterLoading = ref<boolean>(false)
    const sizeGroups = ref<Array<SizeGroup>>([])
    const qualityGroups = ref<Array<QualityGroup>>([])
    const nurseryCompanies = ref<Array<NurseryCompany>>([])
    const customerGroups = ref<Array<CustomerGroup>>([])
    const varietiesByItem = ref<Array<VarietiesByItem>>([])
    const sizesBySizeGroup = ref<Array<SizesBySizeGroup>>([])
    const qualitiesByQualityGroup = ref<Array<QualitiesByQualityGroup>>([])

    const itemSelectVariety = ref(1)

    const showBottomSheet = ref(false)
    const selectResultDialog = ref(
      batchAssignState.value.orderDetailIds.length !== 0 ? false : !!selectedFromResult.value
    )
    const selectedFrom = ref<string | null>(
      batchAssignState.value.orderDetailIds.length !== 0 ? null : selectedFromResult.value
    )

    const choosePropertyItems = ref<Array<Property>>([])

    const nextProperty = computed(() => {
      const key = currentProperty.value
      if (key === listStates.value[listStates.value.length - 1]) {
        scrollBottom()
      }
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = 0; i < LIST_PROPERTY.length; i += 1) {
          if (i > indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      let indexKey = listStates.value.indexOf(key) + 1
      if (
        assignment.orderType &&
        assignment.orderType.id === 1 &&
        listStates.value[indexKey] === 'buyerInfo'
      ) {
        indexKey += 1
      }
      return listStates.value[indexKey]
    })

    const prevProperty = computed(() => {
      const key = currentProperty.value
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = LIST_PROPERTY.length - 1; i >= 0; i -= 1) {
          if (i < indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      let indexKey = listStates.value.indexOf(key) - 1
      if (
        assignment.orderType &&
        assignment.orderType.id === 1 &&
        listStates.value[indexKey] === 'buyerInfo'
      ) {
        indexKey -= 1
      }
      return listStates.value[indexKey]
    })

    const disabledRows = computed(() => {
      if (batchAssignState.value.orderDetailIds.length !== 0) {
        return ['bundleNo', 'buyerInfo', 'customer', 'boxes', 'stems', 'amount', 'price']
      }

      return []
    })

    const requiredRows = computed(() => {
      const rows = Object.keys(assignment).filter((key) => !disabledRows.value.includes(key))
      const indexColors = rows.indexOf('colors')
      if (indexColors > -1) {
        rows.splice(indexColors, 1)
      }
      const indexColor = rows.indexOf('color')
      if (indexColor > -1) {
        rows.splice(indexColor, 1)
      }
      const indexPrice = rows.indexOf('price')
      if (indexPrice > -1) {
        rows.splice(indexPrice, 1)
      }

      if (assignment.orderType && assignment.orderType.name === 'セリ') {
        const priceIndex = rows.indexOf('price')
        if (priceIndex > -1) {
          rows.splice(priceIndex, 1)
        }
      }
      return rows
    })

    // const { data: items, loading: itemLoading } = useQuery(`${endpoints.ITEMS}?with_image=false`)
    // const { data: varieties, loading: varietyLoading } = useQuery(
    //   `${endpoints.VARIETIES}?with_image=false`
    // )
    // const { data: sizes, loading: sizeLoading } = useQuery(endpoints.SIZES)
    // const { data: units, loading: unitLoading } = useQuery(endpoints.UNITS)
    // const { data: colors, loading: colorLoading } = useQuery(endpoints.COLORS)
    // const { data: qualities, loading: qualityLoading } = useQuery(endpoints.QUALITIES)
    // const { data: orderTypes, loading: orderTypeLoading } = useQuery(endpoints.ORDER_TYPES)
    // const { data: boxTypes, loading: boxTypeLoading } = useQuery(endpoints.BOX_TYPE)
    // const { data: customers, loading: customerLoading } = useQuery(endpoints.CUSTOMERS)
    // const { data: assignData, error } = useQuery(() =>
    //   Number(assignId) ? `${endpoints.ASSIGNMENTS}${assignId}` : null
    // )
    // useShowError($toast, error)
    // const { data: orderDetail } = useQuery(() =>
    //   orderDetailId ? `${endpoints.ORDER_DETAILS}${orderDetailId}` : null
    // )

    const getAssignData = async () => {
      onProcessing.value = true
      try {
        assignData.value = []
        orderDetail.value = []
        if (Number(assignId)) {
          assignData.value = toCamelCase(
            (await api.get(`${endpoints.ASSIGNMENTS}${assignId}`)).data
          )
          Object.keys(assignData.value).forEach((key) => {
            if (key === 'breakdowns') {
              assignment.breakdowns = assignData.value.breakdowns?.map((breakdown: IBreakdown) => {
                return {
                  item: items.value.filter((item: CommonProperty) => {
                    return item.id === breakdown.itemId
                  })[0],
                  variety: varieties.value.filter((variety: CommonProperty) => {
                    return variety.id === breakdown.varietyId
                  })[0],
                  size: sizes.value.filter((size: CommonProperty) => {
                    return size.id === breakdown.sizeId
                  })[0],
                  quality: qualities.value.filter((quality: CommonProperty) => {
                    return quality.id === breakdown.qualityId
                  })[0],
                  color: colors.value.filter((color: CommonProperty) => {
                    return color.id === breakdown.colorId
                  })[0],
                  quantity: breakdown.quantity
                }
              })
            } else {
              assignment[key] = assignData.value[key]
            }
            itemSelectVariety.value = assignment.item ? assignment.item.id : 1
          })
        }
        if (orderDetailId) {
          orderDetail.value = toCamelCase(
            (await api.get(`${endpoints.ORDER_DETAILS}${orderDetailId}`)).data
          )
          Object.keys(assignment).forEach((key) => {
            assignment[key] = orderDetail.value[key]
            if (key === 'auctionDate' || key === 'customer') {
              assignment[key] = orderDetail.value.order[key]
            }
          })
          assignment.orderDetailId = orderDetail.value.id
          assignment.breakdowns = []
        }
      } catch (e) {
        showError(e, $toast, root.$t('assign.msg.load_data_fail') as string)
      } finally {
        onProcessing.value = false
      }
    }

    const getVarietyByItem = async () => {
      onProcessing.value = true
      try {
        if ($store.state.common.allVarietyByItem.length === 0) {
          const { data } = await api.get(`${endpoints.VARIETIES}group_by_item`)
          varietiesByItem.value = toCamelCase(data)
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
        } else {
          varietiesByItem.value = $store.state.common.allVarietyByItem
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        onProcessing.value = false
      }
    }

    const getData = async (): Promise<void> => {
      masterLoading.value = true
      onProcessing.value = true
      await getVarietyByItem()
      try {
        const data = await Promise.all([
          api.get(`${endpoints.SIZES}grouped_by_size_group`),
          api.get(`${endpoints.QUALITIES}grouped_by_quality_group`),
          api.get(endpoints.COLORS),
          api.get(endpoints.BOX_TYPE),
          api.get(endpoints.UNITS),
          api.get(endpoints.CUSTOMERS),
          api.get(endpoints.ORDER_TYPES),
          api.get(endpoints.NURSERY_COMPANY),
          api.get(endpoints.CUSTOMER_GROUP)
        ])

        const [
          { data: sizesBySizeGroupData },
          { data: qualitiesByQualityGroupData },
          { data: colorData },
          { data: boxTypesData },
          { data: unitData },
          { data: customersData },
          { data: orderTypesData },
          { data: nurseryCompaniesData },
          { data: customerGroupsData }
        ] = data

        sizesBySizeGroup.value = toCamelCase(sizesBySizeGroupData)
        qualitiesByQualityGroup.value = toCamelCase(qualitiesByQualityGroupData)
        colors.value = toCamelCase(colorData)
        boxTypes.value = toCamelCase(boxTypesData)
        units.value = toCamelCase(unitData)
        customers.value = toCamelCase(customersData)
        nurseryCompanies.value = toCamelCase(nurseryCompaniesData)
        customerGroups.value = toCamelCase(customerGroupsData)
        orderTypes.value = toCamelCase(orderTypesData)

        const match: any = matchData(
          varietiesByItem.value,
          sizesBySizeGroup.value,
          qualitiesByQualityGroup.value
        )

        items.value = match.items
        varieties.value = match.varieties
        sizes.value = match.sizes
        sizeGroups.value = match.sizeGroups
        qualities.value = match.qualities
        qualityGroups.value = match.qualityGroups

        await getAssignData()
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        masterLoading.value = false
        onProcessing.value = false
      }
    }

    watch(
      () => $store.state.listStates.assign,
      () => {
        if ($store.state.listStates.assign.length === 0) {
          listStateStore.value.assign = listStateSetting.value
          $store.commit('setListStates', listStateStore.value)
        }
        listStates.value = getSortStates(
          batchAssignState.value.orderDetailIds.length !== 0
            ? [...defaultObject.listBatchAssignStates]
            : [...defaultObject.listAssignStates],
          $store.state.listStates.assign,
          listStateSetting.value
        )
      }
    )

    const getDefaultStates = async () => {
      onProcessing.value = true
      try {
        const { data } = await api.get(`${endpoints.COMMON}get_setting`)
        listStateSetting.value = toCamelCase(data).setting.states?.assign
          ? toCamelCase(data).setting.states.assign
          : []
        if (listStateStore.value.assign.length === 0) {
          listStateStore.value.assign = listStateSetting.value
          $store.commit('setListStates', listStateStore.value)
        }
        listStates.value = getSortStates(
          batchAssignState.value.orderDetailIds.length !== 0
            ? [...defaultObject.listBatchAssignStates]
            : [...defaultObject.listAssignStates],
          $store.state.listStates.assign,
          listStateSetting.value
        )
      } catch (e) {
        showError(e, $toast, root.$t('order.msg.get_data_failed') as string)
      } finally {
        onProcessing.value = false
      }
    }

    const [create, { loading: createLoading }] = useMutation('post', endpoints.ASSIGNMENTS)
    const [batch, { loading: batchLoading }] = useMutation(
      'post',
      `${endpoints.ASSIGNMENTS}batch_assign`
    )
    const varietyList = computed(() => {
      const itemId = assignment.item?.id

      if (!itemId) return []

      return varieties.value.filter((variety: Variety) => variety.item.id === itemId)
    })

    const sizeList = computed(() => {
      let result: Array<Size> = []

      const itemSizeGroupId = (assignment.item as Item)?.sizeGroup?.id || null
      const varietySizeGroupId = (assignment.variety as Variety)?.sizeGroup?.id || null
      const existedSizeId = (assignment.size as Size)?.id || null

      // case create new assignment
      if (!varietySizeGroupId && !itemSizeGroupId) {
        // set to all sizes
        result = JSON.parse(JSON.stringify(sizes.value))
        return result
      }

      if (varietySizeGroupId) {
        // if variety has sizeGroup: filter by variety sizeGroup
        result = sizes.value.filter((size: Size) => {
          return size.sizeGroup.id === varietySizeGroupId
        })
      } else if (itemSizeGroupId) {
        // if item has sizeGroup: filter by item sizeGroup
        result = sizes.value.filter((size: Size) => {
          return size.sizeGroup.id === itemSizeGroupId
        })
      }

      // case update existed assignment
      // add existed size if filtered list doesn't contain that size
      if (existedSizeId) {
        const containCurrentSize = result.some((size) => size.id === existedSizeId)
        if (!containCurrentSize) {
          result.push(assignment.size as Size)
        }
      }
      return result
    })

    const qualityList = computed(() => {
      let result: Array<Quality> = []

      const itemQualityGroupId = (assignment.item as Item)?.qualityGroup?.id || null
      const varietyQualityGroupId = (assignment.variety as Variety)?.qualityGroup?.id || null
      const existedQualityId = (assignment.quality as Quality)?.id || null

      // case create new assignment
      if (!varietyQualityGroupId && !itemQualityGroupId) {
        // set to all qualities
        result = JSON.parse(JSON.stringify(qualities.value))
        return result
      }

      if (varietyQualityGroupId) {
        // if variety has qualityGroup: filter by variety qualityGroup
        result = qualities.value.filter((quality: Quality) => {
          return quality.qualityGroup.id === varietyQualityGroupId
        })
      } else if (itemQualityGroupId) {
        // if item has qualityGroup: filter by item qualityGroup
        result = qualities.value.filter((quality: Quality) => {
          return quality.qualityGroup.id === itemQualityGroupId
        })
      }

      // case update existed assignment
      // add existed quality if filtered list doesn't contain that quality
      if (existedQualityId) {
        const containCurrentQuality = result.some((quality) => quality.id === existedQualityId)
        if (!containCurrentQuality) {
          result.push(assignment.quality as Quality)
        }
      }
      return result
    })

    const numberDialogValue = computed(() => {
      const defaultValue = currentProperty.value === 'buyerInfo' ? '' : 0
      return assignment[currentProperty.value] || defaultValue
    })

    const chooseProperty = (property: string) => {
      if (disabledRows.value.includes(property)) {
        return
      }

      currentProperty.value = property
      dialog.value = false
      numberDialog.value = false
      colorDialog.value = false
      dateDialog.value = false

      switch (property) {
        case 'auctionDate':
          dateDialog.value = true
          break
        case 'buyerInfo':
          numberDialog.value = true
          break
        case 'bundleNo':
          numberDialog.value = true
          break
        case 'customer':
          choosePropertyItems.value = customers.value
          dialog.value = true
          break
        case 'item':
          choosePropertyItems.value = items.value
          dialog.value = true
          break
        case 'variety':
          choosePropertyItems.value = varietyList.value
          groupDataMaster.value = varietiesByItem.value
          dialog.value = true
          break
        case 'size':
          choosePropertyItems.value = sizeList.value
          groupDataMaster.value = sizesBySizeGroup.value
          dialog.value = true
          break
        case 'colors':
          colorDialog.value = true
          break
        case 'unit':
          choosePropertyItems.value = units.value
          dialog.value = true
          break
        case 'quality':
          choosePropertyItems.value = qualityList.value
          groupDataMaster.value = qualitiesByQualityGroup.value
          dialog.value = true
          break
        case 'orderType':
          choosePropertyItems.value = orderTypes.value
          dialog.value = true
          break
        case 'boxType':
          choosePropertyItems.value = boxTypes.value
          dialog.value = true
          break
        case 'quantity':
          numberDialog.value = true
          break
        case 'boxes':
          numberDialog.value = true
          break
        case 'stems':
          numberDialog.value = true
          break
        case 'price':
          numberDialog.value = true
          break
        case 'amount':
          numberDialog.value = true
          break
        default:
      }
    }

    const selectProperty = (action: string): void => {
      assignment.stems = assignment.stems === 0 ? null : assignment.stems
      assignment.boxes = assignment.boxes === 0 ? null : assignment.boxes
      assignment.quantity = assignment.quantity === 0 ? null : assignment.quantity
      assignment.price = assignment.price === 0 ? null : assignment.price
      assignment.amount = assignment.amount === 0 ? null : assignment.amount
      if (action === 'next') {
        chooseProperty(nextProperty.value as string)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value as string)
      }
    }

    const updateAssignment = (
      action: string,
      item: CommonProperty | number | Item | string
    ): void => {
      const key = currentProperty.value as keyof IAssign
      const oldValue = assignment[key]
      assignment[key] = item

      if (key === 'bundleNo' && item === '') {
        assignment.bundleNo = null
      }

      if (key === 'item') {
        assignment.variety = null
        if (
          oldValue?.sizeGroup?.id !== assignment[key]?.sizeGroup?.id ||
          assignment[key]?.sizeGroup?.id !== assignment.size?.sizeGroup?.id
        ) {
          assignment.size = null
        }
        if (
          oldValue?.qualityGroup?.id !== assignment[key]?.qualityGroup?.id ||
          assignment[key]?.qualityGroup?.id !== assignment.quality?.qualityGroup?.id
        ) {
          assignment.quality = null
        }

        assignment.unit = (item as Item).defaultUnit
        itemSelectVariety.value = (item as Item).id
      }

      if (key === 'variety') {
        const compareValue = oldValue ?? assignment.item
        if (
          compareValue?.sizeGroup?.id !== assignment[key]?.sizeGroup?.id ||
          assignment[key]?.sizeGroup?.id !== assignment.size?.sizeGroup?.id
        ) {
          assignment.size = null
        }
        if (
          compareValue?.qualityGroup?.id !== assignment[key]?.qualityGroup?.id ||
          assignment[key]?.qualityGroup?.id !== assignment.quality?.qualityGroup?.id
        ) {
          assignment.quality = null
        }
      }

      selectProperty(action)
    }

    const generateBody = () => {
      const breakdowns = assignment.breakdowns.map((breakdownDetail: IBreakdown) => {
        return {
          item_id: breakdownDetail.item?.id,
          variety_id: breakdownDetail.variety?.id,
          size_id: breakdownDetail.size?.id,
          quality_id: breakdownDetail.quality?.id,
          color_id: breakdownDetail.color?.id,
          quantity: breakdownDetail.quantity
        }
      })

      return {
        auctionDate: assignment.auctionDate,
        buyerInfo: assignment.buyerInfo,
        bundleNo: assignment.bundleNo,
        customerId: assignment.customer?.id,
        itemId: assignment.item?.id,
        varietyId: assignment.variety?.id,
        sizeId: assignment.size?.id,
        qualityId: assignment.quality?.id,
        colorIds: assignment.colors.map((color: Color) => color.id),
        unitId: assignment.unit?.id,
        orderTypeId: assignment.orderType?.id,
        boxTypeId: assignment.boxType?.id,
        quantity: assignment.quantity,
        boxes: assignment.boxes,
        stems: assignment.stems,
        price: assignment.price,
        amount: assignment.amount,
        remark: assignment.remark?.trim(),
        memo: assignment.memo?.trim(),
        orderDetailId: assignment.orderDetailId,
        packingResultId: assignment.packingResultId,
        breakdowns
      }
    }

    const updateSelectedFromResult = (requestBody: IAssign): void => {
      if (requestBody.packingResultId) {
        $store.commit('setSelectedFromResult', 'PACKING')
      } else {
        $store.commit('setSelectedFromResult', null)
      }
    }

    const validateAssign = async (): Promise<boolean> => {
      const form = refs.form as InstanceType<typeof ValidationObserver>
      const valid = await form.validate()

      return valid
    }

    const createAssign = async (): Promise<boolean> => {
      uploadLoading.value = true
      try {
        const requestBody = generateBody()
        if (batchAssignState.value.orderDetailIds.length !== 0) {
          const data = await batch({
            orderDetailIds: batchAssignState.value.orderDetailIds,
            assignment: requestBody
          })
          orderDetailIdAssigned.value = data.ids
        } else {
          const data = await create(requestBody)
          if (assignment.orderDetailId) {
            // Update is assigned when manual assign for order
            await api.put(
              `${endpoints.ORDER_DETAILS}update_is_assigned/${assignment.orderDetailId}`
            )
          }
          id.value = Number(data.id)
          updateSelectedFromResult(requestBody)
        }
        $toast.success(root.$t('common.msg.create_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      } finally {
        uploadLoading.value = false
      }
      return false
    }

    const updateAssign = async (): Promise<boolean> => {
      uploadLoading.value = true
      try {
        const requestBody = generateBody()
        await api.put(`${endpoints.ASSIGNMENTS}${id.value}`, toSnakeCase(requestBody))
        $toast.success(root.$t('common.msg.update_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      } finally {
        uploadLoading.value = false
      }

      return false
    }

    const deleteAssignDetail = async (): Promise<boolean> => {
      deleteLoading.value = true
      onProcessing.value = true
      try {
        await api.delete(`${endpoints.ASSIGNMENTS}${id.value}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      } finally {
        deleteLoading.value = false
        onProcessing.value = false
      }
      return false
    }

    const save = async (): Promise<void> => {
      if (onProcessing.value) {
        return
      }
      onProcessing.value = true
      const valid = await validateAssign()
      if (!valid) {
        onProcessing.value = false
        return
      }

      let success = false
      if (id.value) {
        success = await updateAssign()
      } else {
        success = await createAssign()

        if (success && orderDetailIdAssigned.value.length !== 0) {
          markOrder.value = true
        }

        if (id.value) {
          $router.replace({
            name: urlPath.ASSIGN_FORM.name,
            params: { assignId: id.value ? id.value.toString() : 'create' },
            query: { auction_date: assignment.auctionDate }
          })
        }
      }
      // If success adding or updating, call to windows.postMesage to automatically update calendar
      // update calendar
      if (success) {
        framebus.emit(frameBusEvent.UPDATE_CALENDAR, { isUpdate: true })
      }
      // avoid double click enter
      if (isOnPC) {
        setTimeout(() => {
          onProcessing.value = false
        }, 300)
      } else {
        onProcessing.value = false
      }
    }

    const close = () => {
      backPage()
    }

    const deleteAssign = () => {
      // remove keydown event to prevent overwrite delete confirm dialog event
      // eslint-disable-next-line no-use-before-define
      document.removeEventListener('keydown', onTyping)

      showDelete.value = true
    }

    const createNew = (isDuplicate: boolean) => {
      const listkey: Array<string> =
        batchAssignState.value.orderDetailIds.length !== 0
          ? defaultObject.listBatchAssignStates
          : defaultObject.listAssignStates
      if (!isDuplicate) {
        listkey.forEach((key: string) => {
          assignment[key] = initResult[key]
          if (key === 'auctionDate') {
            assignment[key] = auctionDate || moment().format('YYYY-MM-DD')
          }
        })
      } else {
        assignment.boxes = initResult.boxes
        assignment.stems = initResult.stems
        assignment.amount = initResult.amount
        assignment.breakdown = initResult.breakdowns
        assignment.orderDetailId = initResult.orderDetailId
        assignment.packingResultId = initResult.packingResultId
        assignment.harvestResultId = initResult.harvestResultId
      }
      id.value = null
      $router.replace({
        name: urlPath.ASSIGN_FORM.name,
        params: { assignId: 'create' },
        query: { auction_date: assignment.auctionDate }
      })
      scrollTop()
    }

    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deleteAssignDetail()
      }
      if (success) {
        close()
      }
      showDelete.value = false

      // Add keydown event
      // eslint-disable-next-line no-use-before-define
      document.addEventListener('keydown', onTyping)
    }

    const handleSelectResult = () => {
      selectResultDialog.value = true
      showBottomSheet.value = false
    }

    const handleSelectedFromResult = (result: PackingResult) => {
      // if order has stems, boxes, amount, then keep them
      const orderProps = ['boxes', 'stems', 'amount']
      Object.keys(assignment)
        .filter(
          (key) =>
            key !== 'auctionDate' &&
            key !== 'orderDetailId' &&
            !disabledRows.value.includes(key) &&
            !orderProps.includes(key)
        )
        .forEach((key) => {
          assignment[key] = result[key as keyof PackingResult] || assignment[key]
        })
      assignment.price = assignment.price ? assignment.price : result.desiredPrice
      // recalculate box
      if (assignment.quantity > 0 && assignment.stems % assignment.quantity === 0) {
        assignment.boxes = assignment.stems / assignment.quantity
      }
      if (result.color) {
        assignment.colors = [result.color]
      }

      assignment.boxes = null
      assignment.packingResultId = result.id
      assignment.breakdowns = result.breakdowns.map((breakdown: IBreakdown) => {
        return {
          item: items.value.filter((item: CommonProperty) => {
            return item.id === breakdown.itemId
          })[0],
          variety: varieties.value.filter((variety: CommonProperty) => {
            return variety.id === breakdown.varietyId
          })[0],
          size: sizes.value.filter((size: CommonProperty) => {
            return size.id === breakdown.sizeId
          })[0],
          quality: qualities.value.filter((quality: CommonProperty) => {
            return quality.id === breakdown.qualityId
          })[0],
          color: colors.value.filter((color: CommonProperty) => {
            return color.id === breakdown.colorId
          })[0],
          quantity: breakdown.quantity
        }
      })
    }

    const getDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(currentProperty.value)}.title`) as string
    }

    const numberDialogTitle = computed((): string => {
      return root.$t(`master.${snakeCase(currentProperty.value)}`) as string
    })

    const addBreakdown = () => {
      assignment.breakdowns = [].concat(assignment.breakdowns, reactive({ ...initBreakdown }))
    }

    const updateBreakdown = (data: IBreakdown, index: number) => {
      if (index > -1) {
        assignment.breakdowns[index] = data
      }
    }

    const removeBreakdown = (index: number) => {
      if (index > -1) {
        assignment.breakdowns.splice(index, 1)
      }
    }

    watch(
      () => [assignment.boxes, assignment.quantity],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([boxes, quantity], [preBoxes, preQuantity]) => {
        if (!assignment.stems || (preBoxes !== null && quantity !== null)) {
          assignment.stems = Number(assignment.boxes * assignment.quantity)
        }
      }
    )

    watch(
      () => assignment.stems,
      () => {
        if (assignment.stems === null) {
          if (assignment.boxes && assignment.quantity) {
            assignment.stems = Number(assignment.boxes * assignment.quantity)
          }
        } else if (Number(assignment.stems) === 0) {
          if (assignment.boxes && assignment.quantity) {
            assignment.stems = Number(assignment.boxes * assignment.quantity)
          }
        }
      }
    )

    watch(
      () => [assignment.price, assignment.stems],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([price, stems]) => {
        if (price !== null && stems !== null) {
          assignment.amount = Number(price * stems)
        }
      }
    )

    const saveCurrentStates = async () => {
      onProcessing.value = true
      try {
        const setting = { data: $store.state.listStates.assign, key: 'assign' }
        await api.put(`${endpoints.COMMON}update_setting_list_states_default`, setting)
        $toast.success(root.$t('common.msg.save_input_state_successful'))
        isShowAction.value = false
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure') as string)
      } finally {
        onProcessing.value = false
      }
    }

    const onTyping = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        save()
      }

      if (id.value) {
        if (event.keyCode === 67) {
          // Press C
          createNew(true)
        } else if (event.keyCode === 78) {
          // Press N
          createNew(false)
        } else if (event.keyCode === 27) {
          // Press Esc
          close()
        } else if (event.keyCode === 68) {
          // Press D
          deleteAssign()
        }
      }

      if (!event.shiftKey && !event.ctrlKey && event.keyCode >= 48 && event.keyCode <= 57) {
        const field = mappingKeyAndField.find(
          (e: MappingKeyAndField) => e.keyCode === event.keyCode
        )
        if (field) {
          chooseProperty(field.field)
        }
      }
    }

    watch(
      () => [dialog.value, numberDialog.value, colorDialog.value, dateDialog.value],
      ([showDialog, showNumberDialog, showColorDialog, showDateDialog]) => {
        if (showDialog || showNumberDialog || showColorDialog || showDateDialog) {
          document.removeEventListener('keydown', onTyping)
        } else {
          document.addEventListener('keydown', onTyping)
        }
      }
    )

    onMounted(async () => {
      await getData()

      // Batch assign
      const { item, variety, size, quality, orderDetailIds } = batchAssignState.value
      if (orderDetailIds.length !== 0) {
        assignment.item = item
        assignment.variety = variety
        assignment.size = size
        assignment.quality = quality
      }
      // Add assignment
      if (orderDetailInfo) {
        const orderDetailTmp = JSON.parse(orderDetailInfo as string)
        assignment.orderDetailId = orderDetailTmp.id
        assignment.orderType = orderDetailTmp.orderType
        assignment.customer = orderDetailTmp.customer
        assignment.item = orderDetailTmp.item
        assignment.variety = orderDetailTmp.variety
        assignment.color = orderDetailTmp.color
        assignment.grade = orderDetailTmp.grade
        assignment.size = orderDetailTmp.size
        assignment.boxType = orderDetailTmp.boxType
        assignment.unit = orderDetailTmp.unit
        assignment.quality = orderDetailTmp.quality
        assignment.quantity = orderDetailTmp.quantity
        assignment.boxes = orderDetailTmp.boxes
        assignment.stems = orderDetailTmp.stems
      }
      await getDefaultStates()
      if (isDuplicated === '1') {
        createNew(isDuplicated === '1')
        isDuplicated = '0'
      }
      document.addEventListener('keydown', onTyping)
    })

    const addEventTyping = (): void => {
      document.addEventListener('keydown', onTyping)
    }

    const removeEventTyping = (): void => {
      document.removeEventListener('keydown', onTyping)
    }

    onUnmounted(() => {
      $store.commit('resetBatchAssignState')
      document.removeEventListener('keydown', onTyping)
    })

    const getNewData = (mode: string) => {
      let listID = []
      let newId = []
      let idQualityGroup = 0
      const newData = $store.state.common.newDataMaster
      itemSelectVariety.value = 1
      switch (mode) {
        case 'customer':
          listID = customers.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            customers.value.push({ ...newData })
          }
          break
        case 'item':
          items.value.push({ ...newData })
          varietiesByItem.value.push({ ...newData, varieties: [] })
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
          // eslint-disable-next-line no-restricted-globals
          // parent.postMessage(varietiesByItem.value, '*')
          framebus.emit(frameBusEvent.ITEM_VARIETY, {
            contents: varietiesByItem.value
          })
          itemSelectVariety.value = $store.state.common.newDataMaster.id
          break
        case 'variety':
          listID = varieties.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            varieties.value.push({ ...newData })
            varietiesByItem.value.map((varietyByItem: VarietiesByItem, index: number) => {
              if (varietyByItem.id === newData.item.id) {
                varietiesByItem.value[index].varieties.push(newData)
              }
              return null
            })
            $store.commit('setAllVarietyByItem', varietiesByItem.value)
            groupDataMaster.value = varietiesByItem.value
            framebus.emit(frameBusEvent.ITEM_VARIETY, {
              contents: varietiesByItem.value
            })
          }
          break
        case 'size':
          listID = sizes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            sizes.value.push({ ...newData })
            sizesBySizeGroup.value.push({ ...newData, sizes: [] })
            groupDataMaster.value = sizesBySizeGroup.value
          }
          break
        case 'box_type':
          listID = boxTypes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            boxTypes.value.push({ ...newData })
          }
          break
        case 'quality':
          listID = qualities.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            idQualityGroup = newData.qualityGroup.id
            qualitiesByQualityGroup.value.map((e: QualityGroup, index: number) => {
              if (e.id === idQualityGroup) {
                qualitiesByQualityGroup.value[index].qualities.push({ ...newData })
              }
              return null
            })
            qualities.value.push({ ...newData })
            groupDataMaster.value = qualitiesByQualityGroup.value
          }
          break
        case 'unit':
          listID = units.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            units.value.push({ ...newData })
          }
          break
        default:
      }
      chooseProperty(currentProperty.value)
      updateAssignment('next', newData)
    }

    return {
      id,
      orderDetailId,
      colors,
      save,
      currentProperty,
      masterLoading,
      assignment,
      dialog,
      colorDialog,
      numberDialog,
      dateDialog,
      numberDialogValue,
      choosePropertyItems,
      numberDialogTitle,
      getDialogTitle,
      selectProperty,
      chooseProperty,
      updateAssignment,
      createLoading,
      batchLoading,
      updateLoading,
      deleteLoading,
      showDelete,
      confirmDelete,
      showBottomSheet,
      selectResultDialog,
      handleSelectResult,
      selectedFrom,
      handleSelectedFromResult,
      requiredRows,
      disabledRows,
      markOrder,
      orderDetailIdAssigned,
      items,
      varieties,
      sizes,
      qualities,
      addBreakdown,
      updateBreakdown,
      removeBreakdown,
      thisPage,
      isShowAction,
      saveCurrentStates,
      sizeGroups,
      qualityGroups,
      nurseryCompanies,
      customerGroups,
      getNewData,
      itemSelectVariety,
      groupDataMaster,
      uploadLoading,
      createNew,
      close,
      getData,
      deleteAssign,
      isOnPC,
      addEventTyping,
      removeEventTyping
    }
  }
})

export default AssignForm























import { defineComponent } from '@vue/composition-api'

import { useMutation } from 'hooks'
import { endpoints, showError } from 'utils'

const MarkOrderDialog = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    },
    orderDetailIds: {
      type: Array,
      required: true
    }
  },
  setup(props, { root, emit }) {
    const { $toast } = root
    const [markOrderDetail, { loading }] = useMutation('post', `${endpoints.ORDER_DETAILS}assigned`)

    const handleMarkOrder = async (): Promise<void> => {
      try {
        await markOrderDetail({ ids: props.orderDetailIds })
        $toast.success(root.$t('common.msg.update_success'))

        emit('on-saved')
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      }
    }

    return { handleMarkOrder, loading }
  }
})

export default MarkOrderDialog








































































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch
} from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment'
import { snakeCase } from 'lodash'

import { useMutation } from 'hooks'
import {
  backPage,
  checkIsOnPC,
  constants,
  defaultObject,
  endpoints,
  frameBusEvent,
  mappingKeyAndField,
  matchData,
  scrollBottom,
  scrollTop,
  showError,
  toCamelCase,
  toSnakeCase,
  urlPath
} from 'utils'
import {
  BoxType,
  CommonProperty,
  IAssign,
  Item,
  MappingKeyAndField,
  QualitiesByQualityGroup,
  Quality,
  QualityGroup,
  Size,
  SizeGroup,
  SizesBySizeGroup,
  Unit,
  VarietiesByItem,
  Variety
} from 'typings'

import {
  ChooseNumber,
  ChooseProperty,
  ConfirmDelete,
  CreateCommonForm,
  SelectInputRow,
  SingleDetailItem
} from 'components'
import { api, framebus } from 'plugins'

interface Property {
  id: number
  name: string
}

const GrowerDetailForm = defineComponent({
  components: {
    CreateCommonForm,
    SelectInputRow,
    ChooseNumber,
    ChooseProperty,
    ConfirmDelete,
    SingleDetailItem
  },
  setup(props, { root, refs }) {
    const thisPage = ref('assign')
    const { $router, $route, $toast, $store } = root
    const { assignId } = $route.params
    let { isDuplicated } = $route.params
    const { auction_date: auctionDate } = $route.query
    const id = ref(Number(assignId) || null)
    const updateLoading = ref(false)
    const deleteLoading = ref(false)
    const onProcessing = ref(false)
    const readonly = ref(false)
    // const createLoading = ref(false)
    const isOnPC = checkIsOnPC()
    const LIST_PROPERTY = [
      'auctionDate',
      'item',
      'variety',
      'quality',
      'size',
      'boxType',
      'unit',
      'quantity',
      'boxes',
      'stems'
    ]

    const listStates = ref<Array<string>>([])
    const groupDataMaster = ref<Array<any>>([])

    const initResult: IAssign = defaultObject.initAssign

    const assignment: IAssign = reactive({
      ...initResult,
      auctionDate: auctionDate ? String(auctionDate) : moment().format('YYYY-MM-DD')
    })

    const uploadLoading = ref(false)
    const currentProperty = ref('')
    const dialog = ref(false)
    const numberDialog = ref(false)
    const dateDialog = ref(false)
    const showDelete = ref(false)

    const items = ref<Array<Item>>([])
    const varieties = ref<Array<Variety>>([])
    const sizes = ref<Array<Size>>([])
    const boxTypes = ref<Array<BoxType>>([])
    const units = ref<Array<Unit>>([])
    const qualities = ref<Array<Quality>>([])
    const assignData = ref<any>([])
    const masterLoading = ref<boolean>(false)
    const sizeGroups = ref<Array<SizeGroup>>([])
    const qualityGroups = ref<Array<QualityGroup>>([])
    const varietiesByItem = ref<Array<VarietiesByItem>>([])
    const sizesBySizeGroup = ref<Array<SizesBySizeGroup>>([])
    const qualitiesByQualityGroup = ref<Array<QualitiesByQualityGroup>>([])

    const itemSelectVariety = ref(1)

    const choosePropertyItems = ref<Array<Property>>([])

    const setReadonlyFields = async () => {
      const userInfo = await $store.getters.getUserInfo
      readonly.value = userInfo.memberRole === constants.memberRole.ROLE_CHILD_GROWER
    }

    const nextProperty = computed(() => {
      const key = currentProperty.value
      if (key === listStates.value[listStates.value.length - 1]) {
        scrollBottom()
      }
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = 0; i < LIST_PROPERTY.length; i += 1) {
          if (i > indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      let indexKey = listStates.value.indexOf(key) + 1
      if (
        assignment.orderType &&
        assignment.orderType.id === 1 &&
        listStates.value[indexKey] === 'buyerInfo'
      ) {
        indexKey += 1
      }
      return listStates.value[indexKey]
    })

    const prevProperty = computed(() => {
      const key = currentProperty.value
      if (listStates.value.indexOf(key) === -1) {
        const indexListProp = LIST_PROPERTY.indexOf(key)
        for (let i = LIST_PROPERTY.length - 1; i >= 0; i -= 1) {
          if (i < indexListProp && listStates.value.indexOf(LIST_PROPERTY[i]) !== -1) {
            return LIST_PROPERTY[i]
          }
        }
        return ''
      }
      let indexKey = listStates.value.indexOf(key) - 1
      if (
        assignment.orderType &&
        assignment.orderType.id === 1 &&
        listStates.value[indexKey] === 'buyerInfo'
      ) {
        indexKey -= 1
      }
      return listStates.value[indexKey]
    })

    const getAssignData = async () => {
      onProcessing.value = true
      try {
        assignData.value = []
        if (Number(assignId)) {
          assignData.value = toCamelCase(
            (await api.get(`${endpoints.GROWER_DELIVERY}${assignId}`)).data
          )
          Object.keys(assignData.value).forEach((key) => {
            assignment[key] = assignData.value[key]
            itemSelectVariety.value = assignment.item ? assignment.item.id : 1
          })
        }
      } catch (e) {
        showError(e, $toast, root.$t('assign.msg.load_data_fail') as string)
      } finally {
        onProcessing.value = false
      }
    }

    const getVarietyByItem = async () => {
      onProcessing.value = true
      try {
        if ($store.state.common.allVarietyByItem.length === 0) {
          const { data } = await api.get(`${endpoints.GROWER_VARIETIES}group_by_item`)
          varietiesByItem.value = toCamelCase(data)
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
        } else {
          varietiesByItem.value = $store.state.common.allVarietyByItem
        }
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        onProcessing.value = false
      }
    }

    const getData = async (): Promise<void> => {
      masterLoading.value = true
      onProcessing.value = true
      await getVarietyByItem()
      try {
        const data = await Promise.all([
          api.get(`${endpoints.SIZES}grouped_by_size_group`),
          api.get(`${endpoints.QUALITIES}grouped_by_quality_group`),
          api.get(endpoints.BOX_TYPE),
          api.get(endpoints.UNITS)
        ])

        const [
          { data: sizesBySizeGroupData },
          { data: qualitiesByQualityGroupData },
          { data: boxTypesData },
          { data: unitData }
        ] = data

        sizesBySizeGroup.value = toCamelCase(sizesBySizeGroupData)
        qualitiesByQualityGroup.value = toCamelCase(qualitiesByQualityGroupData)
        boxTypes.value = toCamelCase(boxTypesData)
        units.value = toCamelCase(unitData)

        const match: any = matchData(
          varietiesByItem.value,
          sizesBySizeGroup.value,
          qualitiesByQualityGroup.value
        )

        items.value = match.items
        varieties.value = match.varieties
        sizes.value = match.sizes
        sizeGroups.value = match.sizeGroups
        qualities.value = match.qualities
        qualityGroups.value = match.qualityGroups

        await getAssignData()
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      } finally {
        masterLoading.value = false
        onProcessing.value = false
      }
    }

    const [create, { loading: createLoading }] = useMutation('post', endpoints.GROWER_DELIVERY)
    const varietyList = computed(() => {
      const itemId = assignment.item?.id

      if (!itemId) return []

      return varieties.value.filter((variety: Variety) => variety.item.id === itemId)
    })

    const sizeList = computed(() => {
      let result: Array<Size> = []

      const itemSizeGroupId = (assignment.item as Item)?.sizeGroup?.id || null
      const varietySizeGroupId = (assignment.variety as Variety)?.sizeGroup?.id || null
      const existedSizeId = (assignment.size as Size)?.id || null

      // case create new assignment
      if (!varietySizeGroupId && !itemSizeGroupId) {
        // set to all sizes
        result = JSON.parse(JSON.stringify(sizes.value))
        return result
      }

      if (varietySizeGroupId) {
        // if variety has sizeGroup: filter by variety sizeGroup
        result = sizes.value.filter((size: Size) => {
          return size.sizeGroup.id === varietySizeGroupId
        })
      } else if (itemSizeGroupId) {
        // if item has sizeGroup: filter by item sizeGroup
        result = sizes.value.filter((size: Size) => {
          return size.sizeGroup.id === itemSizeGroupId
        })
      }

      // case update existed assignment
      // add existed size if filtered list doesn't contain that size
      if (existedSizeId) {
        const containCurrentSize = result.some((size) => size.id === existedSizeId)
        if (!containCurrentSize) {
          result.push(assignment.size as Size)
        }
      }
      return result
    })

    const qualityList = computed(() => {
      let result: Array<Quality> = []

      const itemQualityGroupId = (assignment.item as Item)?.qualityGroup?.id || null
      const varietyQualityGroupId = (assignment.variety as Variety)?.qualityGroup?.id || null
      const existedQualityId = (assignment.quality as Quality)?.id || null

      // case create new assignment
      if (!varietyQualityGroupId && !itemQualityGroupId) {
        // set to all sizes
        result = JSON.parse(JSON.stringify(qualities.value))
        return result
      }

      if (varietyQualityGroupId) {
        // if variety has qualityGroup: filter by variety qualityGroup
        result = qualities.value.filter((quality: Quality) => {
          return quality.qualityGroup.id === varietyQualityGroupId
        })
      } else if (itemQualityGroupId) {
        // if item has qualityGroup: filter by item qualityGroup
        result = qualities.value.filter((quality: Quality) => {
          return quality.qualityGroup.id === itemQualityGroupId
        })
      }

      // case update existed assignment
      // add existed quality if filtered list doesn't contain that quality
      if (existedQualityId) {
        const containCurrentQuality = result.some((quality) => quality.id === existedQualityId)
        if (!containCurrentQuality) {
          result.push(assignment.quality as Quality)
        }
      }
      return result
    })

    const numberDialogValue = computed(() => {
      const defaultValue = currentProperty.value === 'buyerInfo' ? '' : 0
      return assignment[currentProperty.value] || defaultValue
    })

    const chooseProperty = (property: string) => {
      currentProperty.value = property
      dialog.value = false
      numberDialog.value = false
      dateDialog.value = false

      switch (property) {
        case 'auctionDate':
          dateDialog.value = true
          break
        case 'item':
          choosePropertyItems.value = items.value
          dialog.value = true
          break
        case 'variety':
          choosePropertyItems.value = varietyList.value
          dialog.value = true
          groupDataMaster.value = varietiesByItem.value
          break
        case 'size':
          choosePropertyItems.value = sizeList.value
          groupDataMaster.value = sizesBySizeGroup.value
          dialog.value = true
          break
        case 'unit':
          choosePropertyItems.value = units.value
          dialog.value = true
          break
        case 'quality':
          choosePropertyItems.value = qualityList.value
          groupDataMaster.value = qualitiesByQualityGroup.value
          dialog.value = true
          break
        case 'boxType':
          choosePropertyItems.value = boxTypes.value
          dialog.value = true
          break
        case 'quantity':
          numberDialog.value = true
          break
        case 'boxes':
          numberDialog.value = true
          break
        case 'stems':
          numberDialog.value = true
          break
        default:
      }
    }

    const selectProperty = (action: string): void => {
      assignment.quantity = assignment.quantity === 0 ? null : assignment.quantity
      if (action === 'next') {
        chooseProperty(nextProperty.value as string)
      } else if (action === 'prev') {
        chooseProperty(prevProperty.value as string)
      }
    }

    const updateAssignment = (
      action: string,
      item: CommonProperty | number | Item | string
    ): void => {
      const key = currentProperty.value as keyof IAssign
      const oldValue = assignment[key]
      assignment[key] = item

      if (key === 'item') {
        assignment.variety = null
        if (
          oldValue?.sizeGroup?.id !== assignment[key]?.sizeGroup?.id ||
          assignment[key]?.sizeGroup?.id !== assignment.size?.sizeGroup?.id
        ) {
          assignment.size = null
        }
        if (
          oldValue?.qualityGroup?.id !== assignment[key]?.qualityGroup?.id ||
          assignment[key]?.qualityGroup?.id !== assignment.quality?.qualityGroup?.id
        ) {
          assignment.quality = null
        }
        assignment.unit = (item as Item).defaultUnit
        itemSelectVariety.value = (item as Item).id
      }

      if (key === 'variety') {
        const compareValue = oldValue ?? assignment.item
        if (
          compareValue?.sizeGroup?.id !== assignment[key]?.sizeGroup?.id ||
          assignment[key]?.sizeGroup?.id !== assignment.size?.sizeGroup?.id
        ) {
          assignment.size = null
        }
        if (
          compareValue?.qualityGroup?.id !== assignment[key]?.qualityGroup?.id ||
          assignment[key]?.qualityGroup?.id !== assignment.quality?.qualityGroup?.id
        ) {
          assignment.quality = null
        }
      }

      selectProperty(action)
    }

    const generateBody = () => {
      return {
        auctionDate: assignment.auctionDate,
        item: assignment.item?.id,
        variety: assignment.variety?.id,
        size: assignment.size?.id,
        quality: assignment.quality?.id,
        unit: assignment.unit?.id,
        boxType: assignment.boxType?.id,
        quantity: assignment.quantity,
        boxes: assignment.boxes,
        stems: assignment.stems,
        is_sent: false
      }
    }

    const validateAssign = async (): Promise<boolean> => {
      const form = refs.form as InstanceType<typeof ValidationObserver>
      const valid = await form.validate()

      return valid
    }

    const createAssign = async (): Promise<boolean> => {
      uploadLoading.value = true
      try {
        const requestBody = generateBody()
        const data = await create(requestBody)
        id.value = Number(data.id)
        $toast.success(root.$t('common.msg.create_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      } finally {
        uploadLoading.value = false
      }
      return false
    }

    const updateAssign = async (): Promise<boolean> => {
      uploadLoading.value = true
      try {
        const requestBody = generateBody()
        await api.put(`${endpoints.GROWER_DELIVERY}${id.value}`, toSnakeCase(requestBody))
        $toast.success(root.$t('common.msg.update_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      } finally {
        uploadLoading.value = false
      }
      return false
    }

    const deleteAssignDetail = async (): Promise<boolean> => {
      deleteLoading.value = true
      onProcessing.value = true
      try {
        await api.delete(`${endpoints.GROWER_DELIVERY}${id.value}`)
        $toast.success(root.$t('common.msg.delete_success'))
        return true
      } catch (e) {
        showError(e, $toast, root.$t('common.msg.system_failure'))
      } finally {
        deleteLoading.value = false
        onProcessing.value = false
      }
      return false
    }

    const save = async (): Promise<void> => {
      if (onProcessing.value) {
        return
      }
      onProcessing.value = true
      const valid = await validateAssign()
      if (!valid) {
        onProcessing.value = false
        return
      }

      let success = false
      if (id.value) {
        success = await updateAssign()
      } else {
        success = await createAssign()

        if (id.value) {
          $router.replace({
            name: urlPath.ASSIGN_GROWER_FORM.name,
            params: { assignId: id.value ? id.value.toString() : 'create' },
            query: { auction_date: assignment.auctionDate }
          })
        }
      }
      // If success adding or updating, call to windows.postMesage to automatically update calendar
      // update calendar
      if (success) {
        framebus.emit(frameBusEvent.UPDATE_CALENDAR, { isUpdate: true })
      }
      // avoid double click enter
      if (isOnPC) {
        setTimeout(() => {
          onProcessing.value = false
        }, 300)
      } else {
        onProcessing.value = false
      }
    }

    const close = () => {
      backPage()
    }

    const createNew = (isDuplicate: boolean) => {
      const listkey: Array<string> = defaultObject.listAssignStates
      if (!isDuplicate) {
        listkey.forEach((key: string) => {
          assignment[key] = initResult[key]
          if (key === 'auctionDate') {
            assignment[key] = auctionDate || moment().format('YYYY-MM-DD')
          }
        })
      } else {
        assignment.boxes = initResult.boxes
        assignment.stems = initResult.stems
      }
      id.value = null
      $router.replace({
        name: urlPath.ASSIGN_GROWER_FORM.name,
        params: { assignId: 'create' },
        query: { auction_date: assignment.auctionDate }
      })
      scrollTop()
    }

    const deleteAssign = () => {
      // remove keydown event to prevent overwrite delete confirm dialog event
      // eslint-disable-next-line no-use-before-define
      document.removeEventListener('keydown', onTyping)

      showDelete.value = true
    }

    const confirmDelete = async (action: string): Promise<void> => {
      let success = false
      if (action === 'delete') {
        success = await deleteAssignDetail()
      }
      if (success) {
        close()
      }
      showDelete.value = false

      // Add keydown event
      // eslint-disable-next-line no-use-before-define
      document.addEventListener('keydown', onTyping)
    }

    const getDialogTitle = (): string => {
      return root.$t(`master.${snakeCase(currentProperty.value)}.title`) as string
    }

    const numberDialogTitle = computed((): string => {
      return root.$t(`master.${snakeCase(currentProperty.value)}`) as string
    })
    watch(
      () => [assignment.boxes, assignment.quantity],
      () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        if (assignment.boxes !== null && assignment.quantity !== null) {
          assignment.stems = assignment.boxes * assignment.quantity
        }
      }
    )
    const onTyping = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        // Press enter
        save()
      }

      if (id.value) {
        if (event.keyCode === 67) {
          // Press C
          createNew(true)
        } else if (event.keyCode === 78) {
          // Press N
          createNew(false)
        } else if (event.keyCode === 27) {
          // Press Esc
          close()
        } else if (event.keyCode === 68) {
          // Press D
          deleteAssign()
        }
      }

      if (!event.shiftKey && !event.ctrlKey && event.keyCode >= 48 && event.keyCode <= 57) {
        const field = mappingKeyAndField.find(
          (e: MappingKeyAndField) => e.keyCode === event.keyCode
        )
        if (field) {
          chooseProperty(field.field)
        }
      }
    }

    watch(
      () => [dialog.value, numberDialog.value, dateDialog.value],
      ([showDialog, showNumberDialog, showDateDialog]) => {
        if (showDialog || showNumberDialog || showDateDialog) {
          document.removeEventListener('keydown', onTyping)
        } else {
          document.addEventListener('keydown', onTyping)
        }
      }
    )

    onMounted(async () => {
      await setReadonlyFields()
      await getData()

      listStates.value = LIST_PROPERTY
      if (isDuplicated === '1') {
        createNew(isDuplicated === '1')
        isDuplicated = '0'
      }
      document.addEventListener('keydown', onTyping)
    })

    onUnmounted(() => {
      $store.commit('resetBatchAssignState')
      document.removeEventListener('keydown', onTyping)
    })

    const getNewData = (mode: string) => {
      let listID = []
      let newId = []
      let idQualityGroup = 0
      const newData = $store.state.common.newDataMaster
      itemSelectVariety.value = 1
      switch (mode) {
        case 'item':
          items.value.push({ ...newData })
          varietiesByItem.value.push({ ...newData, varieties: [] })
          $store.commit('setAllVarietyByItem', varietiesByItem.value)
          // eslint-disable-next-line no-restricted-globals
          // parent.postMessage(varietiesByItem.value, '*')
          framebus.emit(frameBusEvent.ITEM_VARIETY, {
            contents: varietiesByItem.value
          })
          itemSelectVariety.value = $store.state.common.newDataMaster.id
          break
        case 'variety':
          listID = varieties.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            varieties.value.push({ ...newData })
            varietiesByItem.value.map((varietyByItem: VarietiesByItem, index: number) => {
              if (varietyByItem.id === newData.item.id) {
                varietiesByItem.value[index].varieties.push(newData)
              }
              return null
            })
            $store.commit('setAllVarietyByItem', varietiesByItem.value)
            groupDataMaster.value = varietiesByItem.value
            framebus.emit(frameBusEvent.ITEM_VARIETY, {
              contents: varietiesByItem.value
            })
          }
          break
        case 'size':
          listID = sizes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            sizes.value.push({ ...newData })
            sizesBySizeGroup.value.push({ ...newData, sizes: [] })
            groupDataMaster.value = sizesBySizeGroup.value
          }
          break
        case 'box_type':
          listID = boxTypes.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            boxTypes.value.push({ ...newData })
          }
          break
        case 'quality':
          listID = qualities.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            idQualityGroup = newData.qualityGroup.id
            qualitiesByQualityGroup.value.map((e: QualityGroup, index: number) => {
              if (e.id === idQualityGroup) {
                qualitiesByQualityGroup.value[index].qualities.push({ ...newData })
              }
              return null
            })
            qualities.value.push({ ...newData })
            groupDataMaster.value = qualitiesByQualityGroup.value
          }
          break
        case 'unit':
          listID = units.value.map((item: any) => Number(item.id))
          newId = listID.filter((e: number) => e === $store.state.common.newDataMaster.id)
          if (newId.length === 0) {
            units.value.push({ ...newData })
          }
          break
        default:
      }
      chooseProperty(currentProperty.value)
      updateAssignment('next', newData)
    }

    return {
      id,
      save,
      LIST_PROPERTY,
      currentProperty,
      masterLoading,
      assignment,
      dialog,
      numberDialog,
      dateDialog,
      numberDialogValue,
      choosePropertyItems,
      numberDialogTitle,
      getDialogTitle,
      selectProperty,
      chooseProperty,
      updateAssignment,
      createLoading,
      updateLoading,
      deleteLoading,
      showDelete,
      confirmDelete,
      items,
      varieties,
      sizes,
      qualities,
      thisPage,
      sizeGroups,
      qualityGroups,
      getNewData,
      itemSelectVariety,
      groupDataMaster,
      uploadLoading,
      createNew,
      close,
      getData,
      readonly,
      isOnPC,
      deleteAssign
    }
  }
})

export default GrowerDetailForm
